import React, {useContext} from 'react';
import { MetadataContext } from './Contexts';
import { Credits } from './Credits';
import TextLink from './TextLink';
import Title from './Title.png';
import { firstPagebyBook } from './BookUtil'

function Home() {
    const metadata = useContext(MetadataContext);
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img src={Title} alt="Spy Shit"></img>
            <div style={{ width: '600px', maxWidth: 'calc(100vw - 50px)'}}>
                <div className="spyshit-text">
                    Former agent Frank Gouda must track down the President(s) of the United States.
                </div>
                <div className="spyshit-text" style={{fontSize: '0.5em'}}>
                    With no script, SPY SHIT is passed between {Credits.andrew}, {Credits.caelan}, and {Credits.vedic} every two pages. With this unique format, we've truly created spy content with twists and turns beyond even our own imagination. We invite you to dive deep into the complex and multi-faceted world of SPY SHIT with us.
                </div>
            </div>
            { metadata && <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
                    {firstPagebyBook.map((pageNumber, index) => (
                        <div style={{marginTop: '10px'}} key={index}>
                            <TextLink to={`pages/${pageNumber}`}>Book {index + 1}</TextLink>
                        </div>
                    ))}
                <div style={{marginTop: '20px'}}>
                    <TextLink  to={`/pages/${metadata.lastPage}`}>Latest Page</TextLink>
                </div>
            </div> }
        </div>
    );
}

export default Home;
