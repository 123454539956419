import React from 'react';
import { firstPagebyBook, getBookIndexFromPage } from './BookUtil';

export const Credits = {
    andrew: <Credit name="Andrew Murray" link="https://twitter.com/andrfw_games"/>,
    caelan: <Credit name="Caelan Pollock" link="https://twitter.com/calenquest"/>,
    vedic: <Credit name="vedic_malware" link="https://twitter.com/vedic_malware"/>
};

const CreditOrderByBook = [
    [Credits.andrew, Credits.caelan, Credits.vedic],
    [Credits.andrew, Credits.vedic, Credits.caelan],
];

export const CreditFromPage = (props) => {
    const book = getBookIndexFromPage(props.page);
    console.log(book);
    const pageInBook = props.page - firstPagebyBook[book];
    console.log(pageInBook);
    return CreditOrderByBook[book][Math.floor((pageInBook) / 2) % 3];
}

function Credit(props) {
    return <a href={props.link}>{props.name}</a>;
}