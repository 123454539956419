import React, {useState, useEffect} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Home from './Home.js'
import ComicPage from "./ComicPage";
import NoPage from './NoPage.js';
import { MetadataContext } from './Contexts.js';
import './styles.scss';

export default function App() {
    const [metadata, setMetadata] = useState(null);
    useEffect(() => {
        fetch('https://spyshit.s3-us-west-2.amazonaws.com/metadata.json')
            .then(x => x.json())
            .then(x => setMetadata(x))
    }, []);
    
    return (
        <MetadataContext.Provider value={metadata}>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path={'/pages/:comicpage'} component={ComicPage}/>
                    <Route component={NoPage}/>
                </Switch>
            </BrowserRouter>
        </MetadataContext.Provider>
    );
}