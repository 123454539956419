import React from 'react';
import {Link} from 'react-router-dom';

export default function TextLink(props) {
    if (props.disabled) {
        return <div className="spyshit-textlink-disabled">
            {props.children}
        </div>
    } else {
        return <Link to={props.to}>
            <div className="spyshit-textlink">
                {props.children}
            </div>
        </Link>
    }
}